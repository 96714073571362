// 判断两个obj是否相等
export function deepEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true
  }

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    if (!obj2.hasOwnProperty(key) || !deepEqual(obj1[key], obj2[key])) {
      return false
    }
  }

  return true
}

export function hidePhoneNumber(phoneNumber: string): string {
  if (typeof phoneNumber !== 'string') {
    return phoneNumber
  }

  const len = phoneNumber.length

  // 若手机号小于等于4位数，全部显示
  if (len <= 4) {
    return phoneNumber
  }

  // 隐藏中间部分，保留开头2位和结尾2位
  const visibleLength = 2
  const visibleStart = phoneNumber.slice(0, visibleLength)
  const visibleEnd = phoneNumber.slice(-visibleLength)

  return `${visibleStart}****${visibleEnd}`
}

// localStorage ===> 存储看过的影片
export function setViewStorage(name: string, obj: any) {
  let found = false
  console.log('window.localStorage.getItem(name)', name, obj)
  const data = Object.values(JSON.parse(window.localStorage.getItem(name) || '[]'))
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (deepEqual(data[i], obj)) {
        data[i] = obj
        found = true
        break // 如果只替换第一个匹配值，可以加上 break; 语句
      }
    }
    if (!found) {
      data.unshift(obj)
    }
  } else {
    data.push(obj)
  }
  window.localStorage.setItem(name, JSON.stringify({ ...data }))
}

// localStorage ===> get看过的影片
export function getViewStorage(name: string) {
  return Object.values(JSON.parse(window.localStorage.getItem(name) || '[]'))
}
// localStorage ===> del看过的影片
export function delViewStorage(name: string, ids: any) {
  let data = Object.values(JSON.parse(window.localStorage.getItem(name) || '[]'))
  if (data && data.length > 0) {
    data = data.filter((item: any) => !ids.includes(item.videoId))
  }
  window.localStorage.setItem(name, JSON.stringify({ ...data }))
}
